import React from "react";
import { Modal } from "react-bootstrap";

export default function ImageModel({ isImageModal, image, onhide }) {
  return (
    <>
      <Modal
        show={isImageModal}
        onHide={() => {
          onhide();
        }}
        style={{ display: "block" }}
      >
        <Modal.Header
          className="modal-header"
          style={{
            position: "absolute",
            top: "14px",
            right: "14px",
            zIndex: "99",
            border: "none",
            width: "max-content",
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            {image && (
              <img
                className="w-100"
                style={{ maxHeight: "501px" }}
                src={image}
                alt="Profile"
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
