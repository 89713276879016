import React from "react";
import Chart from "react-apexcharts";

function TopSellingProductOfferChart({ data }) {
  //eslint-disable-next-line
  // Extracting labels and values
  const labels = data.map((item) => item?.product_name);
  const offerCounts = data.map((item) => item.offer_count);

  // Chart options and series data
  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    title: {
      text: "Top Selling Products",
      align: "center",
    },
    xaxis: {
      categories: labels,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#008FFB", "#00E396", "#FF4560", "#775DD0", "#FEB019"],
  };

  const series = [
    {
      name: "Offer Count",
      data: offerCounts,
    },
  ];
  return (
    <div className="card ">
      <div className="card-header py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">
        <h6 className="m-0 fw-bold">Top Selling Product</h6>
      </div>
      <div className="card-body" style={{ position: "relative" }}>
        <Chart
          options={options}
          series={series}
          type="bar"
          width="100%"
          height={300}
        />
      </div>
    </div>
  );
}
export default TopSellingProductOfferChart;
