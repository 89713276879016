import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import st from "./DynamicForm.module.scss";
import { handleKeyDown } from "../../helper/validation";
import { FilterComponent } from "../../components/common/FilterComponent";
import { FiEdit } from "react-icons/fi";

function CategoryList() {
  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [user, setuser] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const columns = () => {
    return [
      {
        name: <span style={{ fontSize: "16px" }}>Category Name</span>,
        selector: (row) => row.category_name,
        sortable: true,
        minWidth: "100px",
        compact: true,
      },
      {
        name: <span style={{ fontSize: "16px" }}>Category Icon</span>,
        selector: (row) => row.icon_url,
        // selector: (row) => row.icon,
        sortable: true,
        minWidth: "100px",
        compact: true,
        cell: (row) => (
          <>
            <img
              className="avatar border"
              style={{ borderRadius: "50px" }}
              src={
                row?.icon_url ? row?.icon_url : ""
                // : "https://tse3.mm.bing.net/th?id=OIP.ZXGqBYoTNttjaD35d-J5RAAAAA&pid=Api&P=0&h=180"
              }
              alt=""
            />{" "}
          </>
        ),
      },
      {
        name: <span style={{ fontSize: "16px" }}>Status</span>,
        selector: (row) => row.icon_url,
        // selector: (row) => row.icon,
        sortable: true,
        minWidth: "100px",
        compact: true,
        cell: (row) => (
          <>
            <div className={` ${st.switchToggle}`}>
              <input
                type="checkbox"
                checked={row?.active}
                onChange={(e) => {
                  categoryStatus(e.target.checked, row?.id);
                }}
              />
            </div>
          </>
        ),
      },
      {
        name: <span style={{ fontSize: "16px" }}>ACTION</span>,
        selector: (row) => {},
        sortable: true,
        minWidth: "100px",
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              onClick={() => {
                setEditRow(row);
                setIseditmodal(true);
                reset({
                  ...row,
                });
                setValue("type", "edit");
              }}
              type="button"
              className="btn btn-outline-secondary"
            >
              <i className="icofont-edit text-success"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                navigate("/subcategory-list", { state: row });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-rounded-right"></i>
            </button>
          </div>
        ),
      },
    ];
  };
  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Category deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getCategoryList();
    }
  };

  async function getCategoryList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      reset();
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCategoryList();
  }, []);

  const onSubmit = async ({ category_name, icon, id, type, next = false }) => {
    try {
      const formData = new FormData();
      formData.append("category_name", category_name);
      if (id) {
        icon &&
          icon.length &&
          icon[0] !== "h" &&
          formData.append("icon", icon[0]);
      } else {
        formData.append("icon", icon[0]);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/${
          type === "add" ? "" : id + "/"
        }`,
        {
          method: type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (response.status === 201) {
          setTimeout(() => {
            toast.success("Category created successfully");
            if (next) {
              navigate("/subcategory-list", { state: responseData?.data });
            }
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200) {
          toast.success("Category updated successfully");
          setIseditmodal(false);
        }
        getCategoryList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  const categoryStatus = async (active, id) => {
    try {
      const formData = new FormData();
      formData.append("active", active);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/category/${id + "/"}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        if (response.status === 200) {
          toast.success(
            `Category is ${active ? "activated" : "deactivated"} successfully`
          );
          setIseditmodal(false);
        }
        getCategoryList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  // Filtering Category based on category name
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = table_row.filter((item) => {
    return (
      item?.category_name &&
      item?.category_name?.toLowerCase().includes(filterText.toLowerCase())
    );
  });
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleImageChange = (e) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setValue("icon_url", newImage);
  };

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Category Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                <button
                  type="button"
                  onClick={() => {
                    reset({ room: null }, { keepValues: false });
                    setIsmodal(true);
                    setValue("type", "add");
                  }}
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Category
                </button>
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <DataTable
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                          highlightOnHover={true}
                          data={filteredItems}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit Category
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue=""
                    id="item"
                    placeholder="Category name"
                    {...register("category_name", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.category_name && (
                    <span style={{ color: "red" }}>
                      Category name is required
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="icon" className="form-label">
                    Category Icon<span className="text-danger"></span>
                  </label>
                  <div
                    className="position-relative"
                    style={{ width: "max-content", borderRadius: "50px" }}
                  >
                    <input
                      {...register("icon", {
                        required: editRow?.icon_url ? false : true,
                        onChange: (e) => {
                          handleImageChange(e);
                        },
                      })}
                      type="file"
                      className={`form-control ${st.editImageModal}`}
                      placeholder="Upload File"
                      accept="image/*"
                    />
                    <div className={`${st.editImage}`}>
                      <FiEdit />
                    </div>
                    {errors.icon && (
                      <span style={{ color: "red" }}>
                        Please select category image
                      </span>
                    )}
                    {watch("icon_url") && (
                      <img
                        className="avatar lg border"
                        style={{ borderRadius: "50px" }}
                        src={
                          watch("icon_url")
                            ? watch("icon_url")
                            : "https://tse3.mm.bing.net/th?id=OIP.ZXGqBYoTNttjaD35d-J5RAAAAA&pid=Api&P=0&h=180"
                        }
                      />
                    )}
                  </div>
                  <br />
                  {/* <input
                    {...register("icon")}
                    type="file"
                    className={"form-control"}
                    placeholder="Upload File"
                  /> */}
                  Recommended the svg icons upload reference will be given in
                  this link{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      window.open("https://www.flaticon.com/", "_blank");
                    }}
                  >
                    {" "}
                    https://www.flaticon.com/
                  </Link>
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIseditmodal(false);
                    reset();
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Category
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Category Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Category name"
                    {...register("category_name", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.category_name && (
                    <span style={{ color: "red" }}>
                      Category name is required
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="icon" className="form-label">
                    Category Icon<span className="text-danger"></span>
                  </label>
                  <input
                    {...register("icon", { required: true })}
                    type="file"
                    className={"form-control"}
                    placeholder="Upload File"
                    accept="image/*"
                  />
                  {errors.icon && (
                    <span style={{ color: "red" }}>
                      Please select category image
                    </span>
                  )}
                  <br />
                  Recommended the svg icons upload reference will be given in
                  this link{" "}
                  <Link
                    to="#"
                    onClick={() => {
                      window.open("https://www.flaticon.com/", "_blank");
                    }}
                  >
                    {" "}
                    https://www.flaticon.com/
                  </Link>
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    setValue("next", true);
                  }}
                  className="btn btn-primary"
                  style={{ background: "#00842a", borderColor: "#00842a" }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Category
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete ?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default CategoryList;
