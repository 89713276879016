import React from "react";
import st from "../../style.module.scss";
import { useFieldArray } from "react-hook-form";
import { Form } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FaRegCircle } from "react-icons/fa";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ nestIndex, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `form.${nestIndex}.nestedOptions`,
  });

  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }}>
            <Form.Group
              className={`${st.formField} ${st.new_formFields} d-flex`}
            >
              <span className="btn">
                <FaRegCircle />
              </span>
              <Form.Control
                type="text"
                placeholder="Option"
                {...register(`form.${nestIndex}.nestedOptions.${k}.Option`, {
                  required: true,
                  setValueAs: (op) => ({ label: op, value: op }),
                })}
              />
              <button className="btn" type="button" onClick={() => remove(k)}>
                <IoClose />
              </button>
            </Form.Group>
          </div>
        );
      })}

      <div>
        <Form.Group className={`${st.formField} ${st.new_formFields} d-flex`}>
          <div className={`${st.tableAction} mb-0`}>
            <button
              style={{ color: "#fff", background: "#7f8699" }}
              type="button"
              className={`btn`}
              onClick={() =>
                append({
                  Option: "",
                })
              }
            >
              Add Option
            </button>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};
