export default function imagePath(path) {
  if (typeof path === "undefined" || path === null) return undefined;
  const httpsUrl = process.env.REACT_APP_BASEURL;
  return path[0] === "/" ? httpsUrl + path : path;
}

// utils/checkUrlType.js
export const checkUrlType = (url) => {
  const pdfPattern = /\.pdf$/i;
  const imagePattern = /\.(jpg|jpeg|png|gif|bmp|webp|svg|jfif|ico)$/i;

  if (pdfPattern.test(url)) {
    return "PDF";
  } else if (
    imagePattern.test(url) ||
    (typeof url === "string" && url.startsWith("blob:"))
  ) {
    return "Image";
  } else {
    return "Unknown";
  }
};
