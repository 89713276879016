import React, { useState, useEffect } from "react";
import st from "../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash, FaFileDownload } from "react-icons/fa";
import {
  handleKeyDown,
  handleKeyDownWithNumbers,
  validatePassword,
} from "../../helper/validation";
import { HiPlusSm } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { checkUrlType } from "../../helper/imagePathHandler";
import ImageModel from "../../components/common/ImageModel";
import GoogleMapComponent from "../../components/App/Maps/Maps";
import { GoogleMapsLoader } from "../../components/App/Maps/GoogleMapsLoader";
import Autocomplete from "react-google-autocomplete";

function VendorDetails() {
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});
  const navigate = useNavigate();
  const location = useLocation();
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageInPopup, setImageInPopup] = useState("");
  const [shopLocation, setShopLocation] = useState({ lat: null, lng: null });

  const [image, setImage] = useState({ value: "", status: false });
  const [docImage1, setdocImage1] = useState({ value: "", status: false });
  const [docImage2, setdocImage2] = useState({ value: "", status: false });

  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmitChangePassword,
    register: registerChangePassword,
    watch: watchChangePassword,
    reset: resetChangePassword,
    formState: { errors: errorsChangePassword },
  } = useForm();

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitChangePassword, setIsSubmitChangePassword] = useState(false);
  const [formStatus, setFormStatus] = useState(true);

  const [reason, setReason] = useState("");
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [reasonError, setReasonError] = useState("");

  const [oldPassword, setOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const toggleOldPasswordVisibility = () => setOldPassword(!oldPassword);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason(value);
    setReasonError("");
  };
  const validateReason = () => {
    if (!reason.trim()) {
      setReasonError("Reason is required");
      return false;
    }
    return true;
  };

  const handleReject = () => {
    if (validateReason()) {
      rejectProfile({
        is_active: false,
        is_staff: true,
        type: "reject",
        reason: reason, // Include reason in the rejection payload
      });

      setIsRejectModal(false); // Close modal after rejection
    }
  };

  useEffect(() => {
    if (userData?.is_superuser) {
      setImage({ value: location?.state?.image, status: false });
      setdocImage1({ value: location?.state?.doc1, status: false });
      setdocImage2({ value: location?.state?.doc2, status: false });
      setShopLocation({
        lat: location?.state?.lat,
        lng: location?.state?.long,
      });
      reset({
        full_name: location?.state?.full_name,
        shop_name: location?.state?.shop_name,
        phone: location?.state?.phone,
        email: location?.state?.email,
        address: location?.state?.address,
        image: location?.state?.image,
        doc1: location?.state?.doc1,
        doc2: location?.state?.doc2,
      });
    } else {
      setImage({ value: userData?.image, status: false });
      setdocImage1({ value: userData?.doc1, status: false });
      setdocImage2({ value: userData?.doc2, status: false });
      setShopLocation({ lat: userData?.lat, lng: userData?.long });

      if (userData && userData?.is_vendor) {
        reset({
          full_name: userData?.full_name,
          shop_name: userData?.shop_name,
          phone: userData?.phone,
          email: userData?.email,
          address: userData?.address,
          image: userData?.image,
          doc1: userData?.doc1,
          doc2: userData?.doc2,
        });
      }
    }
  }, []);

  const onSubmit = async ({
    full_name,
    shop_name,
    phone,
    email,
    address,
    image,
    doc1,
    doc2,
  }) => {
    setIsSubmit(true);
    try {
      let apiId = userData?.is_vendor ? userData?.id : location?.state?.id;

      const formData = new FormData();
      full_name && formData.append("full_name", full_name);
      shop_name && formData.append("shop_name", shop_name);
      address && formData.append("address", address);
      shopLocation?.lat && formData.append("lat", shopLocation?.lat);
      shopLocation?.lng && formData.append("long", shopLocation?.lng);
      address && formData.append("address", address);
      if (doc1 && doc1.length && doc1[0] !== "h") {
        formData.append("doc1", doc1[0]);
      }
      if (doc2 && doc2.length && doc2[0] !== "h") {
        formData.append("doc2", doc2[0]);
      }

      if (image && image.length && image[0] !== "h") {
        formData.append("image", image[0]);
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${
          apiId + "/"
        }`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();

      if (response.status === 200) {
        const responseData = await response.json();
        localStorage.setItem("userData", JSON.stringify(responseData));
        toast.success("Profile updated successfully");
        setTimeout(() => {
          if (userData?.is_superuser) {
            navigate("/vendor-list", { replace: true });
          } else {
            navigate("/dashboard", { replace: true });
          }
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsSubmit(false);
      setFormStatus(false);
    }
  };

  const onSubmitChangePassword = async ({
    old_password,
    password,
    confirm_password,
  }) => {
    setIsSubmitChangePassword(true);
    if (old_password === password) {
      toast.error("Old password and new password cannot be the same");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("email", userData?.email);
      formData.append("old_password", old_password);
      formData.append("password", password);
      formData.append("confirm_password", confirm_password);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/ChangePassword/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        toast.error(data?.msg);
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();

      if (response.status === 200) {
        toast.success("Password updated successfully");
        setIsSubmitChangePassword(false);
        resetChangePassword();
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      toast.error();
    } finally {
      setIsSubmitChangePassword(false);
    }
  };

  const [isUploading, setIsUploading] = useState({
    doc1: false,
    doc2: false,
    doc3: false,
    doc4: false,
  });
  const [uploadProgress, setUploadProgress] = useState({
    doc1: 0,
    doc2: 0,
    doc3: 0,
    doc4: 0,
  });
  const [fileName, setFileName] = useState({
    doc1: "",
    doc2: "",
    doc3: "",
    doc4: "",
  });

  const handleImageChange = (e, type = "image") => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    if (type === "image") {
      setImage({ value: newImage, status: true });
    }
    if (type === "doc1") {
      setdocImage1({ value: newImage, status: true });
    }
    if (type === "doc2") {
      setdocImage2({ value: newImage, status: true });
    }
  };

  const handleFileChange = (event, doc) => {
    const file = event.target.files[0];
    if (file.type.startsWith("image/")) {
      handleImageChange(event, doc);
    } else {
      setFileName((prevFileNames) => ({ ...prevFileNames, [doc]: file.name }));
      setIsUploading((prevIsUploading) => ({
        ...prevIsUploading,
        [doc]: true,
      }));

      // Simulate file upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const newProgress = prevProgress[doc] + 10;
          if (newProgress >= 100) {
            clearInterval(uploadInterval);
            setIsUploading((prevIsUploading) => ({
              ...prevIsUploading,
              [doc]: false,
            }));
          }
          return { ...prevProgress, [doc]: newProgress };
        });
      }, 500); // Simulate upload progress every 500ms
    }
  };

  const onButtonClick = () => {
    const pdfUrl = "Sample.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const rejectProfile = async ({ is_active, is_staff, type, reason }) => {
    try {
      let apiId = userData?.is_vendor ? userData?.id : location?.state?.id;
      const formData = new FormData();
      formData.append("is_active", is_active);
      formData.append("is_staff", is_staff);
      if (type === "reject") {
        formData.append("reason", reason);
        formData.append("status", "rejected");
      }
      formData.append("status", "approved");

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${apiId}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        if (type === "reject") {
          toast.success("Vendor profile rejected successfully");
        } else {
          toast.success("Vendor profile approved successfully");
        }
        setTimeout(() => {
          navigate("/vendor-list", { replace: true });
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  function onhide() {
    setIsImageModal(false);
  }
  /**
  * ${
      location?.state?.view ? "dispalePage" : ""
    }
  */

  function captureShopLocation({ lat, lng }) {
    setShopLocation({ lat, lng });
  }

  // Geocoding function (make sure GoogleMapComponent has this in context)
  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        captureShopLocation({
          lat: location.lat(),
          lng: location.lng(),
        });
      } else {
        console.error("Geocoding failed: " + status);
      }
    });
  };

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    // Update the shopLocation state with lat, lng, and address
    setShopLocation({
      lat: lat,
      lng: lng,
      address: place.formatted_address,
    });
  };

  return (
    <>
      <GoogleMapsLoader>
        <section className={`marginTopMobile30 ${st.pageWrapper}`}>
          <div className={`${st.pageWrapperInside}`}>
            <Card style={{ padding: "45px 0px 20px" }}>
              <Card.Body>
                <div className={`deadline-form `}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3 mb-3">
                      <Col md={12} className={`mt-0`}>
                        <h3 className="fw-bold mb-0">Vendor Profile Details</h3>
                        {/* {formStatus ? (
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setFormStatus(true);
                          }}
                        >
                          Edit
                        </button>
                      )} */}
                      </Col>

                      <Col md={6} className={`${st.formBox}`}>
                        <label className={`${st.labelTitle}`}>
                          Owner name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("full_name", {
                            required: true,
                            disabled: !formStatus,
                          })}
                          className="form-control"
                          placeholder="Owner name"
                          onKeyDown={handleKeyDown} // Add event handler for keydown
                        />
                        {errors.full_name && (
                          <span style={{ color: "red" }}>
                            Owner name is required
                          </span>
                        )}
                      </Col>
                      <Col md={6} className={`${st.formBox}`}>
                        <label className={`${st.labelTitle}`}>
                          Shop name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("shop_name", {
                            required: true,
                            disabled: !formStatus,
                          })}
                          onKeyDown={handleKeyDownWithNumbers}
                          className="form-control"
                          placeholder="Shop name"
                        />
                        {errors.shop_name && (
                          <span style={{ color: "red" }}>
                            Shop name is required
                          </span>
                        )}
                      </Col>
                      <Col md={6} className={`${st.formBox}`}>
                        <label className={`${st.labelTitle}`}>
                          Email<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          {...register("email", { disabled: true })}
                          className="form-control"
                          placeholder="Email"
                        />
                        {/* {errors.email && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )} */}
                      </Col>
                      <Col md={6} className={`mt-3 ${st.formBox}`}>
                        <label className={`${st.labelTitle}`}>
                          Phone number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone number"
                          {...register("phone", { disabled: true })}
                        />
                        {/* {errors.phone && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )} */}
                      </Col>
                    </div>
                    <Row></Row>
                    <Row>
                      <div className="col-md-6 col-sm-12 mt-5 margintopMobile">
                        <div className="mb-2">
                          <label className="form-label">
                            Shop Image
                            <span className="text-danger">*</span> (Only images
                            are allowed)
                          </label>
                          <div className="d-flex gap-3">
                            {image && image?.value && (
                              <div className={`${st.dummyImage}`}>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={image?.value}
                                  alt={`uploaded `}
                                  onClick={() => {
                                    setIsImageModal(true);
                                    setImageInPopup(image?.value);
                                  }}
                                />
                              </div>
                            )}
                            <div className={`${st.dummyImage}`}>
                              <HiPlusSm
                                onClick={() =>
                                  document.getElementById("fileInput").click()
                                }
                              />
                              <input
                                type="file"
                                id="fileInput"
                                accept="image/*"
                                style={{ display: "none" }}
                                {...register("image", {
                                  required: image?.status,
                                  onChange: (e) => handleImageChange(e),
                                  // disabled: !formStatus,
                                })}
                              />
                            </div>
                            {errors.image && (
                              <span className="text-danger">
                                Shop image is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mt-5 margintopMobile">
                        <div className="mb-2">
                          <label className="form-label">
                            Upload citizen ID (NIDA)
                            <span className="text-danger">*</span> (Only images
                            or PDFs are allowed)
                          </label>
                          <div className="uploadImage">
                            {checkUrlType(docImage1?.value) === "Image" && (
                              <div className={`${st.dummyImage}`}>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={docImage1?.value}
                                  alt={`uploaded `}
                                  onClick={() => {
                                    setIsImageModal(true);
                                    setImageInPopup(docImage1?.value);
                                  }}
                                />
                              </div>
                            )}
                            {checkUrlType(watch("doc1")) === "PDF" && (
                              <a
                                href={watch("doc1")}
                                className="btn"
                                target="_blank"
                                rel="noreferrer"
                                onClick={onButtonClick}
                              >
                                <span>
                                  <FaFileDownload />
                                </span>
                              </a>
                            )}

                            <button className="btn">
                              <input
                                type="file"
                                // onChange={(e)=>{
                                //   handleFileChange(e, "doc1");
                                // }}
                                // disabled={!formStatus}
                                accept="image/*,.pdf"
                                className="form-control form-control-lg"
                                {...register("doc1", {
                                  required: docImage1.status,
                                  onChange: (e) => {
                                    handleFileChange(e, "doc1");
                                  },
                                  // disabled: !formStatus,
                                })}
                              />
                              +
                            </button>
                            {isUploading.doc1 && (
                              <div className="imageName w-100 pt-3">
                                <div className="progress-container">
                                  <progress
                                    className="progressBar"
                                    value={uploadProgress.doc1}
                                    max="100"
                                  ></progress>
                                  <span className="progress-value">
                                    {uploadProgress.doc1}%
                                  </span>
                                </div>
                              </div>
                            )}
                            {!isUploading.doc1 && fileName.doc1 && (
                              <div className="imageName w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={fileName.doc1}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                          {errors.doc1 && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12 mt-5 margintopMobile">
                        <div className="mb-2">
                          <label className="form-label">
                            Upload business registration certificate (BRELA)
                            (Only images or PDFs are allowed)
                          </label>
                          <div className="uploadImage">
                            {checkUrlType(docImage2?.value) === "Image" && (
                              <div className={`${st.dummyImage}`}>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src={docImage2?.value}
                                  alt={`uploaded `}
                                  onClick={() => {
                                    setIsImageModal(true);
                                    setImageInPopup(docImage2?.value);
                                  }}
                                />
                              </div>
                            )}
                            {checkUrlType(watch("doc2")) === "PDF" && (
                              <a
                                href={watch("doc2")}
                                className="btn"
                                target="_blank"
                                rel="noreferrer"
                                onClick={onButtonClick}
                              >
                                <span>
                                  <FaFileDownload />
                                </span>
                              </a>
                            )}
                            <button className="btn">
                              <input
                                type="file"
                                className="form-control form-control-lg"
                                accept="image/*,.pdf"
                                {...register("doc2", {
                                  required: false,
                                  onChange: (e) => {
                                    handleFileChange(e, "doc2");
                                  },
                                })}
                              />
                              +
                            </button>
                            {isUploading.doc2 && (
                              <div className="imageName w-100 pt-3">
                                <div className="progress-container">
                                  <progress
                                    className="progressBar"
                                    value={uploadProgress.doc2}
                                    max="100"
                                  ></progress>
                                  <span className="progress-value">
                                    {uploadProgress.doc2}%
                                  </span>
                                </div>
                              </div>
                            )}
                            {!isUploading.doc2 && fileName.doc2 && (
                              <div className="imageName w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={fileName.doc2}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                          {/* {errors.doc2 && (
                      <span className="text-danger">This field is required</span>
                    )} */}
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <Col md={12} className={`mt-3 mb-3 ${st.formBox}`}>
                        <label className={`${st.labelTitle}`}>
                          Shop address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your shop address"
                          {...register("address", {
                            required: true,
                            disabled: !formStatus,
                            // onChange: (event) => {
                            //   // If geocoding should be performed on every change (you can optimize with debounce if needed)
                            //   geocodeAddress(event.target.value);
                            // },
                          })}
                        />
                        {errors.address && (
                          <span style={{ color: "red" }}>
                            Shop address is required
                          </span>
                        )}
                      </Col>
                    </Row>
                    {/* {shopLocation?.lat !== null &&
                    shopLocation?.lng !== null ? (
                      <GoogleMapComponent
                        {...shopLocation}
                        captureShopLocation={captureShopLocation}
                        address={watch("address")}
                        setValue={setValue}
                      />
                    ) : null} */}
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        // onClick={() => {
                        //   setShopLocationModal(true);
                        // }}
                      >
                        Please set your shop address below:
                      </button>
                    </div>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      onPlaceSelected={handlePlaceSelect}
                      options={{ types: ["geocode"] }} // You can adjust or leave empty to get general suggestions
                      style={{
                        width: "100%",
                        padding: "10px",
                        margin: "10px 0",
                      }}
                    />
                    {shopLocation?.lat !== null &&
                    shopLocation?.lng !== null ? (
                      <GoogleMapComponent
                        {...shopLocation}
                        captureShopLocation={captureShopLocation}
                        // address={shopLocation?.address}
                        // setValue={setValue}
                      />
                    ) : null}

                    <div className={`mt-5 ${st.buttonBottom}`}>
                      {location?.state?.is_vendor &&
                        !location?.state?.is_active &&
                        !location?.state?.is_staff && (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setIsRejectModal(true);
                              }}
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={() => {
                                rejectProfile({
                                  is_active: true,
                                  is_staff: true,
                                  type: "approve",
                                });
                              }}
                            >
                              Approve
                            </button>{" "}
                          </>
                        )}

                      {!(
                        location?.state?.is_vendor &&
                        !location?.state?.is_active &&
                        !location?.state?.is_staff
                      ) &&
                        !location?.state?.view && (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmit}
                          >
                            Submit
                          </button>
                        )}
                    </div>
                  </form>
                </div>

                {userData?.is_vendor && (
                  <div className="deadline-form mt-5">
                    <form
                      onSubmit={handleSubmitChangePassword(
                        onSubmitChangePassword
                      )}
                    >
                      <div className="row g-3 mb-3">
                        <Col md={12} className={`mt-0`}>
                          <h3>Change Password</h3>
                        </Col>

                        <Col md={6}>
                          <div className={`${st.formBox}`}>
                            <label className={`${st.labelTitle}`}>
                              Old Password
                            </label>
                            <div className="position-relative passwordShow">
                              <input
                                type={oldPassword ? "text" : "password"}
                                className="form-control form-control-lg"
                                placeholder="Old Password"
                                {...registerChangePassword("old_password", {
                                  validate: validatePassword,
                                })}
                              />
                              {oldPassword ? (
                                <FaEye onClick={toggleOldPasswordVisibility} />
                              ) : (
                                <FaEyeSlash
                                  onClick={toggleOldPasswordVisibility}
                                />
                              )}
                            </div>
                          </div>
                          {errorsChangePassword.old_password && (
                            <span className="text-danger">
                              {errorsChangePassword.old_password.message}
                            </span>
                          )}
                        </Col>
                        <Col md={6}>
                          <div className={`${st.formBox}`}>
                            <label className={`${st.labelTitle}`}>
                              New Password
                            </label>
                            <div className="position-relative passwordShow">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control form-control-lg"
                                placeholder="New Password"
                                {...registerChangePassword("password", {
                                  validate: validatePassword,
                                })}
                              />
                              {showPassword ? (
                                <FaEye onClick={togglePasswordVisibility} />
                              ) : (
                                <FaEyeSlash
                                  onClick={togglePasswordVisibility}
                                />
                              )}
                            </div>
                          </div>
                          {errorsChangePassword.password && (
                            <span className="text-danger">
                              {errorsChangePassword.password.message}
                            </span>
                          )}
                        </Col>
                        <Col md={6}>
                          <div className={`${st.formBox}`}>
                            <label className={`${st.labelTitle}`}>
                              Confirm Password
                            </label>
                            <div className="position-relative passwordShow">
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control form-control-lg"
                                placeholder="Confirm Password"
                                {...registerChangePassword("confirm_password", {
                                  required: "Confirm password is required",
                                  validate: (value) =>
                                    value === watchChangePassword("password") ||
                                    "Passwords do not match",
                                })}
                              />
                              {showConfirmPassword ? (
                                <FaEye
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              ) : (
                                <FaEyeSlash
                                  onClick={toggleConfirmPasswordVisibility}
                                />
                              )}
                            </div>
                          </div>
                          {errorsChangePassword.confirm_password && (
                            <span className="text-danger">
                              {errorsChangePassword.confirm_password.message}
                            </span>
                          )}
                        </Col>
                      </div>
                      <div className={`mt-5 ${st.buttonBottom}`}>
                        <button type="submit" className="btn btn-primary">
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </section>
      </GoogleMapsLoader>

      <ImageModel
        isImageModal={isImageModal}
        image={imageInPopup}
        onhide={onhide}
      />

      <Modal
        show={isRejectModal}
        onHide={() => {
          setIsRejectModal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Reject Vendor Profile
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <div>
              <label>Reason:</label>
              <textarea
                rows={5}
                value={reason}
                className="w-100 rounded mt-2 mb-3"
                onChange={handleReasonChange}
              ></textarea>
              {reasonError && <div className="text-danger">{reasonError}</div>}
            </div>
            <div className={`d-flex gap-2 ${st.modalButtonBottom}`}>
              <button
                onClick={() => {
                  setIsRejectModal(false);
                  setReason("");
                  setReasonError("");
                }}
                type="button"
                className="btn btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handleReject}
                type="button"
                className="btn btn-primary"
              >
                Reject
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default VendorDetails;
