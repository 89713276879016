import React, { useState, useCallback, useMemo } from "react";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useGoogleMaps } from "./GoogleMapsLoader"; // Import the centralized loader

const containerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "6px",
};

function GoogleMapComponent({
  lat = -3.745,
  lng = -38.523,
  captureShopLocation,
  address,
  // setValue,
}) {
  const { isLoaded } = useGoogleMaps(); // Use the loader from context
  const center = useMemo(() => ({ lat, lng: lng }), [lat, lng]);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Geocode Address to Lat/Lng
  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        const newPosition = { lat: location.lat(), lng: location.lng() };
        setMarkerPosition(newPosition);
        captureShopLocation(newPosition); // Update parent component
      } else {
        console.error("Geocoding failed: " + status);
      }
    });
  };

  // Reverse Geocode Lat/Lng to Address
  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // setValue("address", results[0].formatted_address);
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Reverse geocoding failed: " + status);
      }
    });
  };

  const onMapClick = useCallback(
    (event) => {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();
      setMarkerPosition({ lat: newLat, lng: newLng });
      captureShopLocation({ lat: newLat, lng: newLng });

      // Trigger reverse geocoding on map click
      reverseGeocode(newLat, newLng);
    },
    [captureShopLocation]
  );

  // Geocode the address when it changes
  useMemo(() => {
    if (address) {
      geocodeAddress(address); // Geocode address on input change
    }
  }, [address]);

  const initialZoom = 10;
  const mapOptions = {
    gestureHandling: "none",
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        center={markerPosition}
        zoom={initialZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onMapClick} // Add onClick handler to update marker position
      >
        {/* Marker with dynamic position */}
        <MarkerF position={markerPosition} />
      </GoogleMap>
      {/* {currentAddress && <p>Current Address: {currentAddress}</p>} */}
    </>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapComponent);
