import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images/forgot-password.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("email", data?.email);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/${
          location?.state?.userType === "vendor"
            ? "vendor-password-reset-request"
            : "admin-password-reset-request"
        }/`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        // const errorData = await response.json();
        const data = await response.json();
        if (
          data &&
          data.email?.[0] === "No user is associated with this email"
        ) {
          toast.error("Email is not registered");
        } else {
          // toast.success(data.msg); //
          toast.error(Object.values(data).join(", ") || "Something went wrong");
          console.error("Failed to submit form");
        }
      }

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        localStorage.removeItem("token");

        setTimeout(() => {
          navigate("/verification", {
            state: {
              email: data?.email,
              userType:
                location?.state?.userType === "admin" ? "admin" : "vendor",
            },
          });
        }, 2000);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };
  return (
    <div
      className="w-100 p-3 p-md-5 card border-0 shadow-sm"
      style={{ maxWidth: "30rem" }}
    >
      <form className="row g-1 p-3 p-md-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 text-center mb-5">
          {/* <img src={Images} className="w240 mb-4" alt="" /> */}
          <h1>Forgot password?</h1>
          <span>
            Enter the email address you used when you joined and we'll send you
            instructions to reset your password.
          </span>
        </div>
        <div className="col-12">
          <div className="mb-2">
            <label className="form-label">Email address</label>
            <input
              type="text"
              id="email"
              className="form-control form-control-lg"
              placeholder="Email address"
              {...register("email", {
                pattern: {
                  value:
                    /^(?!.*\.{2})(?!\.)[a-zA-Z0-9._%+-]+(?<!\.)@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\.)$/,
                  message: "Email is invalid",
                },
                required: "Email address is required",
              })}
            />
            {errors.email?.type === "required" && (
              <p role="alert" className="text-danger">
                {errors.email.message}
              </p>
            )}
            {errors.email?.type === "pattern" && (
              <span className="text-danger">Email is invalid</span>
            )}
          </div>
        </div>
        <div className="col-12 text-center mt-4">
          <Button
            type="submit"
            className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
          >
            SUBMIT
          </Button>
        </div>
        <div className="col-12 text-center mt-4">
          <span className="text-muted">
            <Link
              to={process.env.PUBLIC_URL + `/sign-in/`}
              className="text-secondary"
            >
              Back to Sign in
            </Link>
          </span>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
