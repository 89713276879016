import React from "react";

const TimeComponent = ({ dateString }) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Define the month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Get the hours and minutes for the time
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return (
    <div>
      <span>
        {hours}:{minutes}
        {ampm}
      </span>
    </div>
  );
};

export default TimeComponent;
