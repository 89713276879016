import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "./components/common/Sidebar";
import AddModal from "./components/common/AddModal";
import MainIndex from "./screens/MainIndex";

const ProtectedRoute = ({
  isAuthenticated,
  allowedRoles,
  userRole = "admin",
  history,
}) => {
  const activekey = () => {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? "/" + res : "/";
    const activeKey1 = res;
    return activeKey1;
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to={`/sign-in/`} replace />;
  }

  // if (!allowedRoles.includes(userRole)) {
  //   return <Navigate to="/not-authorized" replace />;
  // }

  return (
    <div id="ebazar-layout" className="theme-blue">
      <Sidebar activekey={activekey()} history={history} />
      <AddModal />
      <MainIndex />
    </div>
  );
};

export default ProtectedRoute;
