import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import ChatTab from "../../components/App/chat/ChatTab";
import EcommerceTab from "../../components/App/chat/EcommerceTab";
import ContactTab from "../../components/App/chat/ContactTab";
import ChatBox from "../../components/App/ChatBox/ChatBox";
import { collection, onSnapshot, doc, getFirestore } from "firebase/firestore";
import logoChat from "../../assets/images/gallery/Communication.svg";
import { useParams } from "react-router";
import useMediaQuery from '@mui/material/useMediaQuery';

const firestore = getFirestore();
function Chat() {
  const params = useParams();
  const matches  = useMediaQuery('(min-width:991px)');

  const [chatHeads, setChatHeads] = useState([]);
  const [search, setSearch] = useState("");
  const [checkMediaQueryStatus, setCheckMediaQueryStatus] = useState(false);
  const senderId =
    params?.sender_id ?? JSON.parse(localStorage.getItem("userData"))?.id; // Assuming role is stored as 'admin'

  useEffect(() => {
    const fetchChatHeads = async () => {
      const chatHeadRef = doc(firestore, "chatrooms", "chatHead");
      const senderCollectionRef = collection(chatHeadRef, senderId.toString());

      const unsubscribe = onSnapshot(senderCollectionRef, (snapshot) => {
        const chatHeadsDoc = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChatHeads(chatHeadsDoc);
      });

      return () => unsubscribe();
    };

    fetchChatHeads();
  }, [senderId]);


  function checkMediaQuery(status){
    setCheckMediaQueryStatus(status)
  }
  return (
    <div className="body d-flex">
      <div className="container-xxl p-0">
        <div className="row g-0">
          <div className="col-12 d-flex mt-5 displaymobile">
          {matches &&  <div
              id="tabboxes"
              className="card card-chat border-right border-top-0 border-bottom-0  w380 chatDesign"
            >
              <Tab.Container defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <div className="px-4 py-3 topsearchBar">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <Nav
                        className="nav nav-pills justify-content-between text-center"
                        role="tablist"
                      >
                        {/* <Nav.Link
                          className="flex-fill rounded border-0 nav-link "
                          eventKey="first"
                          href="#chat-recent"
                        >
                          Chat
                        </Nav.Link> */}
                        {/* <Nav.Link
                          className="flex-fill rounded border-0 nav-link"
                          eventKey="second"
                          href="#chat-groups"
                        >
                          Ecommerce Groups
                        </Nav.Link>
                        <Nav.Link
                          className="flex-fill rounded border-0 nav-link"
                          eventKey="third"
                          href="#chat-contact"
                        >
                          Contact
                        </Nav.Link> */}
                      </Nav>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="tab-content border-top">
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="first"
                        id="chat-recent"
                        role="tabpanel"
                      >
 
                        <ChatTab buyerList={chatHeads} search={search} checkMediaQuery={checkMediaQuery} checkMediaQueryStatus={checkMediaQueryStatus}/>
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="second"
                        id="chat-groups"
                        role="tabpanel"
                      >
                        <EcommerceTab />
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="third"
                        id="chat-contact"
                        role="tabpanel"
                      >
                        <ContactTab />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>} 

            {!matches && !checkMediaQueryStatus &&  <div
              id="tabboxes"
              className="card card-chat border-right border-top-0 border-bottom-0  w380 chatDesign"
            >
              <Tab.Container defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <div className="px-4 py-3 topsearchBar">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <Nav
                        className="nav nav-pills justify-content-between text-center"
                        role="tablist"
                      >
                        {/* <Nav.Link
                          className="flex-fill rounded border-0 nav-link "
                          eventKey="first"
                          href="#chat-recent"
                        >
                          Chat
                        </Nav.Link> */}
                        {/* <Nav.Link
                          className="flex-fill rounded border-0 nav-link"
                          eventKey="second"
                          href="#chat-groups"
                        >
                          Ecommerce Groups
                        </Nav.Link>
                        <Nav.Link
                          className="flex-fill rounded border-0 nav-link"
                          eventKey="third"
                          href="#chat-contact"
                        >
                          Contact
                        </Nav.Link> */}
                      </Nav>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="tab-content border-top">
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="first"
                        id="chat-recent"
                        role="tabpanel"
                      >
 
                        <ChatTab buyerList={chatHeads} search={search} checkMediaQuery={checkMediaQuery} checkMediaQueryStatus={checkMediaQueryStatus}/>
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="second"
                        id="chat-groups"
                        role="tabpanel"
                      >
                        <EcommerceTab />
                      </Tab.Pane>
                      <Tab.Pane
                        className="tab-pane fade  show"
                        eventKey="third"
                        id="chat-contact"
                        role="tabpanel"
                      >
                        <ContactTab />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>} 
        
        {matches && <div className="card card-chat-body border-0  w-100 pb-4 px-3 pt-3">
              {Object.keys(params)?.length === 2 ? (
                <ChatBox />
              ) : (
                <div className="chatBoxRoom">
                  <div className="m-auto">
                    <img src={logoChat} alt="logo" />
                    <h3>Get Started</h3>
                  </div>
                </div>
              )}
            </div>}

            {!matches && checkMediaQueryStatus && <div className="card card-chat-body border-0  w-100 pb-4 px-3 pt-3">
              {Object.keys(params)?.length === 2 ? (
                <ChatBox  checkMediaQuery={checkMediaQuery} checkMediaQueryStatus={checkMediaQueryStatus}/>
              ) : (
                <div className="chatBoxRoom">
                  <div className="m-auto">
                    <img src={logoChat} alt="logo" />
                    <h3>Get Started</h3>
                  </div>
                </div>
              )}
            </div>}
            
          </div>
        </div>
      </div>
    </div>
  );
}
export default Chat;
