import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader1 from "../../components/common/PageHeader1";
import st from "./DynamicForm.module.scss";
import { toast } from "react-toastify";

function ProductFormList() {
  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [user, setuser] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();
  const columns = () => {
    return [
      {
        name: " ID",
        selector: (row) => row.id,
        sortable: true,
        compact: true,
      },
      {
        name: "Category",
        selector: (row) => row.category?.category_name,
        // cell: (row) => (
        //   <>
        //     <img className="avatar rounded lg border" src={row.image} alt="" />{" "}
        //     <span className="px-2">
        //       <Link to={process.env.PUBLIC_URL + "/customer-detail"}>
        //         {row.name}
        //       </Link>
        //     </span>
        //   </>
        // ),
        sortable: true,
        compact: true,
        minWidth: "200px",
      },
      {
        name: "Sub Category",
        selector: (row) => row.subcategory?.subcategory_name,
        sortable: true,
        compact: true,
      },
      {
        name: "Status",
        selector: (row) => row.icon_url,
        // selector: (row) => row.icon,
        sortable: true,
        compact: true,
        minWidth: "100px",
        cell: (row) => (
          <>
            <div className={` ${st.switchToggle}`}>
              <input type="checkbox" />
            </div>
          </>
        ),
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              onClick={() => {
                setIseditmodal(true);
                navigate(`/product/edit-form/${row?.id}`, {
                  state: row,
                });
              }}
              type="button"
              className="btn btn-outline-secondary"
            >
              <i className="icofont-edit text-success"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
          </div>
        ),
      },
    ];
  };
  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/dynamic-form/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Product form deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getProductList();
    }
  };

  async function getProductList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/dynamic-form/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb" style={{ backgroundColor: "transparent" }}>
            <li className="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Library</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Data
            </li>
          </ol>
        </nav>
        <PageHeader1
          imageShow={location?.state?.icon_url}
          pagetitle={`${location?.state?.subcategory_name}`}
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {/* <button
                  to="/product/add-form"
                  className="btn btn-primary btn-set-task w-sm-100"
                ></button> */}
                <button
                  onClick={() => {
                    navigate(`/product/add-form`, {
                      state: location?.state,
                    });
                  }}
                  type="button"
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Product
                  Form{" "}
                </button>
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        columns={columns()}
                        data={table_row}
                        defaultSortField="title"
                        pagination
                        selectableRows={false}
                        className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                        highlightOnHover={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit Product Forms
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlhtmlFor="item1" className="form-label">
                    Product Form Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item1"
                    value="Joan Dyer"
                    onChange={() => {}}
                  />
                </div>
                <div className="col-sm-12">
                  <label htmlhtmlFor="taxtno1" className="form-label">
                    Product Form Profile
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="taxtno1"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    value="South Africa"
                    onChange={() => {}}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlhtmlFor="abc1" className="form-label">
                    Product Form Register date
                  </label>
                  <input
                    type="date"
                    className="form-control w-100"
                    id="abc1"
                    value="2021-03-12"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlhtmlFor="mailid" className="form-label">
                    Mail
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="mailid"
                    value="JoanDyer@gmail.com"
                    onChange={() => {}}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlhtmlFor="phoneid" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneid"
                    value="202-555-0983"
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label className="form-label">Total Order</label>
                  <input
                    type="text"
                    className="form-control"
                    value="02"
                    onChange={() => {}}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <div className="modal-footer">
          <div className={`${st.modalButtonBottom}`}>
            <button
              type="button"
              onClick={() => {
                setIseditmodal(false);
              }}
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Done
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade show"
        id="expadd"
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Product Forms
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Product Forms Name
                  </label>
                  <input type="text" className="form-control" id="item" />
                </div>
                <div className="col-sm-12">
                  <label htmlFor="taxtno" className="form-label">
                    Product Forms Profile
                  </label>
                  <input type="File" className="form-control" id="taxtno" />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="depone" className="form-label">
                    Country
                  </label>
                  <input type="text" className="form-control" id="depone" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="abc" className="form-label">
                    Product Forms Register date
                  </label>
                  <input type="date" className="form-control w-100" id="abc" />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-6">
                  <label htmlFor="abc11" className="form-label">
                    Mail
                  </label>
                  <input type="text" className="form-control" id="abc11" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="abc111" className="form-label">
                    Phone
                  </label>
                  <input type="text" className="form-control" id="abc111" />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label className="form-label">Total Order</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <div className={`${st.modalButtonBottom}`}>
            <button
              onClick={() => {
                setIsmodal(false);
              }}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Done
            </button>
            <button type="submit" className="btn btn-primary">
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Product Form
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ProductFormList;
