export const PlanData = [
  {
    id: 1,
    name: "Free",
    list: ["List up to 10 products.", "Least priority for product visibility."],
  },
  {
    id: 2,
    name: "Silver",
    list: ["List up to 50 products.", "2nd priority for product visibility."],
  },
  {
    id: 3,
    name: "Gold",
    list: ["List unlimited products.", "Top priority for product visibility."],
  },
];
