import React from "react";
import OculusVR from "../../components/Products/ProductDetail/OculusVR";
import PageHeader1 from "../../components/common/PageHeader1";
import { useLocation } from "react-router";

function SingleProductDetails() {
  const location = useLocation();

  return (
    <div className="container-xxl">
      <PageHeader1 pagetitle="Product Details" />
      <div className="row g-3 mb-3">
        <OculusVR product={location?.state} />
      </div>
    </div>
  );
}
export default SingleProductDetails;
