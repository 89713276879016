import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router";
import { FaStar } from "react-icons/fa";
import img5 from "../../../assets/images/img5.jpg";
import DateComponent from "../../common/DateComponent";
import StarRating from "../../common/StarComponent";
import ReadMore from "../../common/ReadMore";

function OculusVR({ product }) {
  const [first_img, setFirst_img] = useState(product?.product_images[0]);
  const [active, setActive] = useState(true);
  const [readMore, setReadMore] = useState(true);
  const navigate = useNavigate();

  const [productReviews, setProductReviews] = useState([]);

  async function getProductReviews() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/product/review/?product=${product?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setProductReviews(data ?? []);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  useEffect(() => {
    getProductReviews();
  }, []);

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body card-scroll">
          <div className="product-details">
            <div className="row">
              <div className="col-lg-6 my-3">
                <div className="product-details-image mt-50">
                  <div className="product-thumb-image">
                    <div
                      className="product-thumb-image-active nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                    >
                      {product?.product_images?.map((item) => {
                        return (
                          <span
                            className=" single-thumb lift"
                            onClick={() => {
                              setFirst_img(item);
                            }}
                          >
                            <img src={item?.image_url} alt="" />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div className="product-image">
                    <div
                      className="product-image-active tab-content"
                      id="v-pills-tabContent"
                    >
                      <span className="single-image lift">
                        <img src={first_img?.image_url} alt="" />{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-3">
                <div className="product-details-content mt-45">
                  <div className="product-edit">
                    <h2 className="fw-bold fs-4">{product?.title}</h2>
                    <FaRegEdit
                      onClick={() => {
                        navigate(`/product-details/${product?.id}`, {
                          state: {
                            type: "edit",
                            id: product?.id,
                            title: product?.title,
                            original_price: product?.original_price,
                            discount_price: product?.discount_price,
                            description: product?.description,
                            category: {
                              value: product?.category?.id,
                              label: product?.category?.category_name,
                            },
                            sub_category: {
                              value: product?.sub_category?.id,
                              label: product?.sub_category?.subcategory_name,
                            },
                            warranty: product?.warranty,
                            product_form_data: product?.product_form_data,
                            vendor: product?.user,
                            product_images: product?.product_images,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="product-price">
                    <h6 className="price-title fw-bold">Price</h6>
                    <p className="sale-price">{product?.discount_price} TZS</p>
                    <p className="regular-price text-danger">
                      {product?.original_price} TZS
                    </p>
                  </div>
                  <p>{product?.description}</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="product-tabs-box">
                  <ul className="product-tabs-inner">
                    {" "}
                    <li
                      className={`${active ? "active" : ""}`}
                      onClick={() => {
                        setActive(true);
                      }}
                    >
                      Product Details
                    </li>
                    <li
                      className={`${!active ? "active" : ""}`}
                      onClick={() => {
                        setActive(false);
                      }}
                    >
                      Reviews
                    </li>
                  </ul>
                  <></>
                  {active ? (
                    <div className="col-md-12">
                      <div className="product-details-content mt-4">
                        <ul className="productList">
                          {product &&
                            product?.product_form_data &&
                            Object.entries(product?.product_form_data)?.map(
                              ([key, value]) => (
                                <li>
                                  <span>{key}</span>
                                  <span>
                                    {typeof value === "string"
                                      ? value
                                      : value?.label}
                                  </span>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        {productReviews?.map(
                          ({ review, rating, created_at, user }) => {
                            return (
                              <div className="col-md-6">
                                <div className="card reviews-box">
                                  <div className="reviews-box-inner">
                                    <div className="reviews-box-img">
                                      <img
                                        src={`${
                                          user?.image
                                            ? user?.image
                                            : "https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180"
                                        }`}
                                        alt="img"
                                      />
                                    </div>
                                    <div className="reviews-box-text">
                                      <h4>{user?.full_name}</h4>
                                      <p>
                                        <DateComponent
                                          dateString={created_at}
                                        />
                                      </p>
                                      <span>
                                        <StarRating rating={rating} />
                                      </span>
                                    </div>
                                  </div>
                                  {/* <p className="text-read">
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard
                                    dummy..........
                                    {readMore ? (
                                      <>
                                        Lorem Ipsum is simply dummy text of the
                                        printing and typesetting industry. Lorem
                                        Ipsum has been the industry's standard
                                        dummy..........
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setReadMore(false);
                                          }}
                                        >
                                          read less
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          type="button"
                                          onClick={() => {
                                            setReadMore(true);
                                          }}
                                        >
                                          read more
                                        </button>
                                      </>
                                    )}
                                  </p> */}

                                  <ReadMore maxCharacterCount={100}>
                                    {review}
                                  </ReadMore>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OculusVR;
